import React, { useState } from "react";
import { transactionPinInterface } from "./transactionpin-interface";
import { Button, Drawer } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { OtpInput } from "reactjs-otp-input";
import { useNavigate } from "react-router-dom";
import { openNotificationWithIcon } from "../../utils/helper";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const TransactionPin = ({
  visible,
  closeModal,
  paymentType,
  paymentDetails = [],
  loading = false,
  onSubmit,
}: transactionPinInterface) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState("");
  const [showPin, setShowPin] = useState(true);

  const handleChange = (otp: string) => setOtp(otp);

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      if (otp.trim().length === 0) {
        openNotificationWithIcon(
          "warning",
          "Transaction PIN",
          "Transaction is required"
        );
      } else {
        onSubmit?.(otp);
      }
    }
  };

  return (
    <Drawer
      onClose={closeModal}
      open={visible}
      placement="bottom"
      title={undefined}
      closeIcon={false}
      className="trans-pin-drawer"
      destroyOnClose
    >
      <div className="flex justify-end ">
        <div className="cursor-pointer w-fit" onClick={closeModal}>
          <CloseOutlined />
        </div>
      </div>
      <div>
        <div className="mb-8">
          <div className="mb-4">
            <h1 className="text-2xl font-semibold text-center font-ibmplexsans text-appcolorblue">
              {step === 1 ? (
                <>
                  Confirm <span className="capitalize">{paymentType}</span>
                </>
              ) : (
                <>Input PIN</>
              )}
            </h1>

            {step === 2 && (
              <p className="text-center">
                Input pin to complete <span>{paymentType}</span>
              </p>
            )}
          </div>

          {step === 1 ? (
            <>
              {paymentDetails.length ? (
                <div className="p-4 mb-3 rounded-lg shadow-lg bg-appcolorwhite">
                  {paymentDetails.map((item, index) => (
                    <div
                      className={`flex justify-between font-poppins ${
                        paymentDetails?.length - 1 === index ? "" : "mb-4"
                      }`}
                      key={`payment-details-${index}-${item.title.toLowerCase()}`}
                    >
                      <p className="w-[45%] font-semibold font-poppins text-[12px]">
                        {" "}
                        {item.title}
                      </p>
                      <p className="w-[65%] flex justify-end font-poppins text-right text-[12px]">
                        {item?.value}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
            </>
          ) : (
            <>
              <div className="mb-1">
                <OtpInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span>-</span>}
                  inputStyle={{
                    height: "3rem",
                    width: "3rem",
                  }}
                  containerStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  isInputSecure={showPin}
                  isInputNum={true}
                />
              </div>

              <div className="flex justify-center gap-2 text-center">
                <p
                  className="flex items-center gap-1 cursor-pointer"
                  onClick={() => {
                    setShowPin(!showPin);
                  }}
                >
                  {showPin ? "Check" : "Unsee"} PIN
                  <span>
                    {!showPin ? (
                      <EyeIcon className="w-4 h-4 " />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 " />
                    )}
                  </span>
                </p>
                <p
                  className="text-center cursor-pointer w-fit text-appcolorblue"
                  onClick={() => {
                    navigate("/dashboard/update-payment-pin");
                  }}
                >
                  Forgot PIN
                </p>
              </div>
            </>
          )}
        </div>

        <div>
          <Button
            className="mt-4 mb-4"
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
            loading={loading}
          >
            {step === 1 ? "Confirm" : "Complete"}{" "}
            <span className="pl-1 capitalize">{paymentType}</span>
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default TransactionPin;
