import React, { useEffect, useState } from "react";
import Inputwithaddon from "../../../components/inputwithaddon/inputwithaddon";
import {
  Alert,
  Button,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from "antd";
import { REACT_APP_BASE_URL } from "../../../utils/appdetails";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import {
  openNotificationWithIcon,
  updateCommonItems,
  validatePhoneNumber,
} from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { handleBVNVerification } from "../../../api/base/base";
import { accountVerification } from "./accounts-interface";
import { updateUser } from "../../../store/userSlice/userSlice";

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const VerifyBvn = ({
  submitting,
  setSubmitting,
  verificationType,
  setVerificationTodo,
  verificationTodo,
}: accountVerification) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState({
    phone: "",
  });

  const state = useSelector((state: RootState) => ({
    token: state.user.token,
    user: state.user.user,
  }));

  const { token, user } = state;

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0].status === "uploading") {
      setLoading(true);
      setImageUrl("");
      setUrl("");
    }
    if (newFileList[0].status === "done") {
      setLoading(false);
      if (newFileList[0].response.status === "success") {
        message.success("Selfie uploaded successfully");
        setUrl(newFileList[0]?.response?.data);
      } else {
        openNotificationWithIcon(
          "error",
          "Upload Selfie",
          newFileList[0]?.response?.message
        );
      }

      // Get this url from response in real world.
      getBase64(newFileList[0].originFileObj as RcFile, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
    if (newFileList[0].status === "error") {
      setLoading(false);
      openNotificationWithIcon(
        "error",
        "Upload Selfie",
        "Selfie could not be uploaded"
      );
    }
  };

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  useEffect(() => {
    if (url.length > 0) {
      updateField("url", url);
    }
  }, [url]);

  const onFinish = async (values: any) => {
    const { phone } = values;
    setError(null);
    setSubmitting(true);
    const newErrors = { ...errors };

    if (!validatePhoneNumber(`+234${phone}`)) {
      newErrors["phone"] = "Invalid phone number";
    } else {
      newErrors["phone"] = "";
    }

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        const res = await handleBVNVerification({
          number: values?.number,
          phone: `+234${phone}`,
          photo_url: values?.url,
        });

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Verify BVN", message);

          dispatch(
            updateUser({
              user: data,
            })
          );

          // const checkFromResponse = data?.kyc?.details.filter(
          //   (item: any) => item?.parameter === verificationType
          // );

          // if (checkFromResponse.length > 0) {
          // const newVerificationTodo = [...verificationTodo];
          // const getIndex = newVerificationTodo.findIndex(
          //   (item: any) => item?.parameter === verificationType
          // );
          // newVerificationTodo[getIndex].status = checkFromResponse[0].status;
          // setVerificationTodo(newVerificationTodo);

          let newVerificationTodo: any[] = [];

          newVerificationTodo = updateCommonItems(
            verificationTodo,
            data?.kyc?.details
          );

          setVerificationTodo(newVerificationTodo);
          // }
        } else {
          setError(message);
        }

        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
        message.error("Something went wrong: Verify BVN");
      }
    } else {
      setSubmitting(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div>
      {error != null && (
        <div className="mb-2">
          <Alert message={error} type="error" className="w-full" />
        </div>
      )}

      <Form
        className="w-full"
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="BVN"
          name="number"
          rules={[
            {
              required: true,
              message: "BVN is required",
            },
          ]}
        >
          <Input placeholder="BVN" />
        </Form.Item>

        <Form.Item
          validateStatus={errors?.phone.length > 0 ? "error" : undefined}
          help={errors?.phone.length > 0 ? errors.phone : undefined}
          label="Phone Number"
          name="phone"
          rules={[
            {
              required: true,
              message: "Phone Number is required",
            },
          ]}
        >
          <Inputwithaddon
            updateField={updateField}
            name="phone"
            placeHolder="8000000000"
            addonBefore="+234"
          />
        </Form.Item>

        <Form.Item
          name="url"
          // rules={[
          //   {
          //     required: true,
          //     message: "Selfie is required",
          //   },
          // ]}
          label={<span>Upload Selfie</span>}
        >
          <Upload
            disabled={submitting}
            maxCount={1}
            accept={".png, .jpg, .jpeg"}
            beforeUpload={beforeUpload}
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            onChange={handleChange}
            action={`${REACT_APP_BASE_URL}/misc/file-upload`}
            headers={{
              Authorization: `Bearer ${token}`,
            }}
            showUploadList={false}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            htmlType="submit"
            className="mb-2 "
            disabled={submitting || loading}
            loading={submitting}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifyBvn;
