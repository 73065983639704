import React, { useState } from "react";
import { selectBeneficiarymodalProps } from "./transfers-interface";
import { Button, Modal } from "antd";
import Inputselect from "../../../components/inputselect/inputselect";
import { useNavigate } from "react-router-dom";

const Selectbeneficiarymodal = ({
  visible,
  closeModal,
  loading,
  beneficiaries,
  loadingBeneficiaries,
  updateLookupFromSelectBeneficiary,
}: selectBeneficiarymodalProps) => {
  const navigate = useNavigate();

  const [currentBeneficiary, setCurrentBeneficiary] = useState<string | null>(
    null
  );
  return (
    <div>
      <Modal
        onCancel={closeModal}
        onOk={closeModal}
        open={visible}
        footer={null}
        destroyOnClose
      >
        <div>
          <div className="flex items-center justify-between">
            <h1 className="mb-2 text-2xl font-semibold font-ibmplexsans text-appcolorblue">
              Select Beneficiary
            </h1>

            <span
              className="px-2 py-1 mr-3 text-xs font-black cursor-pointer rounded-2xl bg-appcolorblue text-appcolorwhite"
              onClick={() => {
                navigate("/dashboard/beneficiary");
              }}
            >
              Add Beneficiary
            </span>
          </div>

          <div className="mt-4">
            <div>
              <Inputselect
                placeHolder="Select Beneficiary"
                updateField={(_, value) => {
                  setCurrentBeneficiary(value);
                }}
                name={"recipient_id"}
                selectOptions={beneficiaries}
                loading={loadingBeneficiaries}
                selectOptionLabel="beneficiaryLabel"
                alternativeValue="recipient_id"
                showSearch={true}
              />
            </div>

            <div className="flex justify-end gap-2 mt-4">
              <div className="">
                <Button
                  className="red-btn"
                  disabled={false}
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>

              <div>
                <Button
                  disabled={loading || currentBeneficiary === null}
                  onClick={() => {
                    updateLookupFromSelectBeneficiary(
                      currentBeneficiary as string
                    );
                    closeModal();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Selectbeneficiarymodal;
