import { Alert, Button, Form, Input, message } from "antd";
import { nanoid } from "nanoid";
import React, { useCallback, useEffect, useState } from "react";
import Inputselect from "../../../components/inputselect/inputselect";
import {
  externalAccountLookUp,
  getBeneficiaries,
  getSupportedBanksInstitutions,
  getWalletsDetails,
  internalAccountLookUp,
  makeTransfer,
} from "../../../api/wallet/wallets";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  updateReloadWallet,
  updateUser,
} from "../../../store/userSlice/userSlice";
import {
  commaNumber,
  openNotificationWithIcon,
  transformCurrencyToSymbol,
  validateAccountNumber,
} from "../../../utils/helper";
import Inputamount from "../../../components/inputamount/inputamount";
import { useNavigate } from "react-router-dom";
import { useEnabledTransactionPin } from "../../../customhooks/useEnabledTransactionPin";
import TransactionPin from "../../../components/transactionpin/transactionpin";
import Skeletonloader from "../../../components/skeletonloader/skeletonloader";

import { debounce } from "lodash";
import Selectbeneficiarymodal from "./selectbeneficiarymodal";

const TransfersInternal = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hasPinEnabled = useEnabledTransactionPin();

  const [currentWalletId, setCurrentWalletId] = useState(undefined);
  const [supportedBanks, setSupportedBanks] = useState([]);
  const [loadingSupportedBanks, setLoadingSupportedBanks] = useState(false);
  const [loadingBeneficiaries, setLoadingBeneficiaries] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState<any>([]);
  const [wallets, setWallets] = useState<any[]>([]);
  const [loadingWallets, setLoadingWallets] = useState(false);
  const [errors, setErrors] = useState({
    currency: "",
    beneficiary: "",
    amount: "",
    identifier: "",
    number: "",
    code: "",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState([
    {
      id: nanoid(),
      name: "Accounts",
      active: false,
    },
    {
      id: nanoid(),
      name: "Wallets",
      active: true,
    },
  ]);

  const [paymentSummaryDetails, setPaymentSummaryDetails] = useState<
    {
      title: string;
      value: any;
    }[]
  >([]);
  const [detailsToBeSubmitted, setDetailsToBeSubmitted] = useState<any | null>(
    null
  );
  const [showTransactionPin, setShowTransactionPin] = useState(false);
  const [showFindBeneficiary, setShowFindBeneficiary] = useState(false);
  const [lookingUpAccount, setLookingUpAccount] = useState(false);
  const [lookupResult, setLookupResult] = useState<
    | {
        name: string;
        bank: string;
        number?: number;
        phone?: string;
      }[]
    | null
  >(null);

  const closeTransactionPin = () => {
    if (!loading) {
      setShowTransactionPin(false);
      setPaymentSummaryDetails([]);
      setDetailsToBeSubmitted(null);
    }
  };

  const state = useSelector((state: RootState) => ({
    user: state.user.user,
    reloadWallet: state.user.reloadWallet,
  }));

  const { user } = state;

  const switchTabs = (valueIndex: number) => {
    const newTabs = [...tabs];
    newTabs.forEach((item, index) => {
      item.active = false;
      if (valueIndex === index) {
        item.active = true;
      }
    });
    setTabs(newTabs);

    form.resetFields();
    form.setFieldValue("beneficiary", undefined);
    form.setFieldValue("identifier", undefined);
    form.setFieldsValue({
      recipient_id: undefined,
      amount: undefined,
      remark: undefined,
      // currency: undefined,
      wallet_id: undefined,
      code: undefined,
      number: undefined,
      identifier: undefined,
    });
    setErrors({
      currency: "",
      beneficiary: "",
      amount: "",
      identifier: "",
      number: "",
      code: "",
    });
    setError(null);
    setBeneficiaries([]);

    setPaymentSummaryDetails([]);
    // setShowTransactionPin(false);
    setDetailsToBeSubmitted(null);

    setLookupResult(null);
    fetchBeneficiaries();
  };

  const constructPaymentSummaryDetails = (values: {
    remark: string;
    // currency: any;
    identifier: string;
    amount: string;
    recipient_id: string;
    wallet_id: string;
  }) => {
    const incomingBeneficiary = beneficiaries.filter(
      (item: any) => item.recipient_id === values.recipient_id
    );

    const detail = [
      {
        title: "Amount",
        value: `${transformCurrencyToSymbol(
          incomingBeneficiary[0]?.currency
        )}${commaNumber(values.amount)}`,
      },
      {
        title: tabs[0]?.active ? "Account Number" : "Phone Number",
        value: tabs[0]?.active
          ? form.getFieldValue("number")
          : lookupResult?.[0]?.phone,
      },
      {
        title: "Bank",
        value: lookupResult?.[0]?.bank,
      },
      {
        title: "Name",
        value: lookupResult?.[0]?.name,
      },
    ];

    setPaymentSummaryDetails(detail);
  };

  const followThroughAfterPin = async (otp: string) => {
    try {
      setError(null);
      setLoading(true);

      const res = await makeTransfer(
        {
          ...detailsToBeSubmitted,
          authorization_pin: otp,
        },
        tabs[0].active ? "single-external-transfer" : "single-internal-transfer"
      );

      const { status, message, data } = res.data;

      if (status === "success") {
        openNotificationWithIcon("success", "Transfer", message);

        form.resetFields();
        setPaymentSummaryDetails([]);
        setShowTransactionPin(false);
        setDetailsToBeSubmitted(null);

        getWallet();
      } else {
        setError(message);
        openNotificationWithIcon("error", "Transfer", message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong: Transfer");
    }
  };

  const onFinish = async (values: any) => {
    setError(null);
    setLoading(true);
    const newErrors = { ...errors };

    setErrors((prevState) => ({
      ...prevState,
      ...newErrors,
    }));

    if (Object.values(newErrors).every((item) => item === "")) {
      try {
        let dataToSend = {
          remark: values?.remark,
          // currency: values?.currency,
          identifier: values?.identifier,
          amount: values?.amount,
          recipient_id: values?.recipient_id,
          wallet_id: values?.wallet_id,
          code: values?.code,
          number: values?.number,
        };

        setDetailsToBeSubmitted(dataToSend);

        if (hasPinEnabled) {
          constructPaymentSummaryDetails(dataToSend);
          setLoading(false);
          setShowTransactionPin(true);
          return false;
        }

        const res = await makeTransfer(
          {
            ...dataToSend,
          },
          tabs[0].active
            ? "single-external-transfer"
            : "single-internal-transfer"
        );

        const { status, message, data } = res.data;

        if (status === "success") {
          openNotificationWithIcon("success", "Transfer", message);

          form.resetFields();

          getWallet();
        } else {
          setError(message);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Something went wrong: Transfer");
      }
    } else {
      setLoading(false);
    }
  };

  const [lookUpError, setLookUpError] = useState(null);
  const accountLookUp = async () => {
    try {
      setLookingUpAccount(true);
      setLookUpError(null);
      setLookupResult(null);

      let res;

      if (tabs[0]?.active) {
        res = await externalAccountLookUp({
          code: form.getFieldValue("code") as string,
          number: form.getFieldValue("number") as string,
        });
      } else {
        res = await internalAccountLookUp({
          identifier: form.getFieldValue("identifier") as string,
        });
      }

      const { status, message, data } = res.data;

      if (status === "success") {
        setLookupResult(tabs[0]?.active ? data : [data]);
        setLookUpError(null);
      } else {
        setLookUpError(message);
        setLookupResult(null);
        // openNotificationWithIcon("error", "Account Lookup", message);
      }

      setLookingUpAccount(false);
    } catch (error) {
      setLookingUpAccount(false);
      message.error("Something went wrong:  Account lookup");
    }
  };

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      accountLookUp();
    }, 500),
    [accountLookUp]
  );

  const updateField = (name: string, value: string) => {
    form.setFields([
      {
        name,
        value,
        errors: [],
      },
    ]);
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
    // not applicable again
    if (name === "wallet_id") {
      let findWallet = wallets.filter((item) => item?.wallet_id === value);
      form.setFieldValue("currency", findWallet?.[0]?.currency);
      form.setFieldsValue({
        recipient_id: undefined,
        amount: undefined,
        remark: undefined,
        code: undefined,
        number: undefined,
        identifier: undefined,
      });
      setPaymentSummaryDetails([]);
      // setShowTransactionPin(false);
      setDetailsToBeSubmitted(null);
      setLookupResult(null);
    }

    if (name === "identifier") {
      debouncedSearch(value);
    }
  };

  const getWallet = async () => {
    setLoadingWallets(true);
    try {
      const res = await getWalletsDetails();

      const { status, data } = res.data;

      if (status === "success") {
        let newWallets: any = [];
        data.forEach((item: any) => {
          newWallets.push({
            ...item,
            walletLabel: `${item?.currency.toUpperCase()} WALLET - ${transformCurrencyToSymbol(
              item?.currency
            )} ${commaNumber(parseFloat(item?.available_balance).toFixed(2))}`,
          });
        });

        setWallets(newWallets);
        if (wallets.length > 0) {
          form.setFieldValue("wallet_id", newWallets[0]?.wallet_id);
        }

        dispatch(
          updateUser({
            user: { ...user, wallets: data },
          })
        );

        dispatch(
          updateReloadWallet({
            reloadWallet: false,
          })
        );
      } else {
        message.warning(`Get wallets, ${res.data.message}`);
      }

      setLoadingWallets(false);
    } catch (error) {
      setLoadingWallets(false);
      message.error("Something went wrong: Get wallets");
    }
  };

  useEffect(() => {
    getWallet();
    form.setFieldValue("currency", "ngn");

    // eslint-disable-next-line
  }, []);

  const closeShowBeneficiary = () => {
    setShowFindBeneficiary(false);
  };

  const updateLookupFromSelectBeneficiary = (recipient_id: string) => {
    const findBeneficiary = beneficiaries.filter((item: any) => {
      return item?.recipient_id === recipient_id;
    });

    setLookupResult(findBeneficiary);
    if (tabs[0]?.active) {
      form.setFieldValue("number", findBeneficiary?.[0]?.number);
      form.setFieldValue("code", findBeneficiary?.[0]?.code);
    } else {
      form.setFieldValue("identifier", findBeneficiary?.[0]?.phone);
    }
  };

  useEffect(() => {
    const code = form.getFieldValue("code");
    const accountNumber = form.getFieldValue("number");

    if (tabs[0].active) {
      if (code !== undefined && accountNumber !== undefined) {
        const newErrors = { ...errors };

        newErrors["number"] = validateAccountNumber(accountNumber);

        setErrors((prevState) => ({
          ...prevState,
          ...newErrors,
        }));

        if (
          Object.values({ number: newErrors.number }).every(
            (item) => item === ""
          )
        ) {
          accountLookUp();
        } else {
          setLookupResult(null);
        }
      } else {
        setLookupResult(null);
      }
    } else {
    }
  }, [form.getFieldValue("code"), form.getFieldValue("number"), tabs]);

  const fetchAvailableBanks = async () => {
    setLoadingSupportedBanks(true);
    try {
      const res = await getSupportedBanksInstitutions({
        country_code: "ng",
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        setSupportedBanks(data);
      } else {
        openNotificationWithIcon("error", "Supported Banks", message);
      }

      setLoadingSupportedBanks(false);
    } catch (error) {
      setLoadingSupportedBanks(false);
      message.error("Something went wrong:  Supported banks");
    }
  };

  const fetchBeneficiaries = async () => {
    setLoadingBeneficiaries(true);
    try {
      const res = await getBeneficiaries({
        currency: "ngn",
        beneficiaryType: tabs[0].active ? "External" : "Internal",
      });

      const { status, message, data } = res.data;

      if (status === "success") {
        let newBeneficiaries: any[] = [];
        data.forEach((item: any) => {
          newBeneficiaries.push({
            ...item,
            beneficiaryLabel: `${item?.name} - ${item?.bank} ${item?.number}`,
          });
        });
        setBeneficiaries(newBeneficiaries);
      } else {
        openNotificationWithIcon("error", "Beneficiaries", message);
      }

      setLoadingBeneficiaries(false);
    } catch (error) {
      setLoadingBeneficiaries(false);
      message.error("Something went wrong: Beneficiaries");
    }
  };

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  useEffect(() => {
    if (wallets.length > 0) {
      if (tabs[0]?.active) {
        fetchAvailableBanks();
      }
    }
  }, [tabs, wallets]);

  // useEffect(() => {
  //   if (wallets.length > 0 && !loadingWallets) {
  //     console.log(wallets?.[0]?.wallet_id);
  //     form.setFieldValue("wallet_id", wallets?.[0]?.wallet_id);
  // 		setCurrentWalletId(wallets?.[0]?.wallet_id);
  //   }
  // }, [wallets, loadingWallets, currentWalletId]);

  return (
    <div className="mt-2">
      <div className="w-11/12 mx-auto lg:w-3/12">
        <div className="flex items-baseline justify-between">
          <h1 className="mb-4 text-3xl font-semibold font-ibmplexsans text-appcolorblue">
            Transfer
          </h1>
        </div>

        <div className="pb-16">
          {/* <div className="grid grid-cols-2 rounded-2xl bg-appcolorshade p-[0.37rem] w-full mx-auto mb-6">
            {tabs.map((item, index) => (
              <div
                className={`font-semibold text-xs text-center cursor-pointer py-4 font-poppins ${
                  item.active ? "bg-appcolorwhite rounded-xl" : "bg-transparent"
                }`}
                onClick={() => {
                  if (!loading) {
                    switchTabs(index);
                  }
                }}
                key={item.id}
              >
                {item.name}
              </div>
            ))}
          </div> */}

          {error != null && (
            <div className="flex justify-center mb-4">
              <Alert message={error} type="error" className="w-full" />
            </div>
          )}

          <Form onFinish={onFinish} layout="vertical" form={form}>
            <p className="mb-2 text-sm text-black font-poppins">Wallet</p>
            {loadingWallets ? (
              <Skeletonloader height={"3rem"} />
            ) : (
              <div className="p-4 rounded-md shadow-md bg-appcolorblue ">
                <p className="text-lg text-white font-poppins">
                  {wallets[0]?.walletLabel}
                </p>
              </div>
            )}

            <div className="flex justify-end mt-1">
              <p
                onClick={() => {
                  setShowFindBeneficiary(true);
                }}
                className={`cursor-pointer  text-appcolorblue w-fit`}
              >
                Find Beneficiary
              </p>
            </div>
            {/* <Form.Item
              validateStatus={errors?.currency.length > 0 ? "error" : undefined}
              help={errors?.currency.length > 0 ? errors.currency : undefined}
              label="Wallet"
              name="wallet_id"
              rules={[
                {
                  required: true,
                  message: "Wallet is required",
                },
              ]}
            >
              <Inputselect
                placeHolder="Select Wallet"
                updateField={updateField}
                name={"wallet_id"}
                selectOptions={wallets}
                loading={loadingWallets}
                selectOptionLabel="walletLabel"
                alternativeValue="wallet_id"
                selectValue={form.getFieldValue("wallet_id")}
              />
            </Form.Item> */}

            <div className="relative mt-3">
              {tabs[0].active ? (
                <>
                  <Form.Item
                    label="Beneficiary Account Number"
                    name="number"
                    rules={[
                      {
                        required: true,
                        message: "Account Number is required",
                      },
                    ]}
                    validateStatus={
                      errors?.number.length > 0 ? "error" : undefined
                    }
                    help={errors?.number.length > 0 ? errors.number : undefined}
                  >
                    <Input
                      maxLength={10}
                      type="number"
                      placeholder=" Account Number"
                      onChange={(e) => {
                        updateField("number", e?.target?.value);
                      }}
                      value={form.getFieldValue("number")}
                    />
                  </Form.Item>

                  {form.getFieldValue("code") !== undefined &&
                    errors?.number.length === 0 &&
                    form.getFieldValue("number") !== undefined && (
                      <div className="mb-2 mt-[-0.5rem]">
                        {lookingUpAccount ? (
                          <Skeletonloader width="100%" height="2.7rem" />
                        ) : (
                          <>
                            {lookupResult && (
                              <div className="p-2 font-semibold text-green-400 bg-green-100 rounded-md font-poppins">
                                {lookupResult?.[0]?.name}
                              </div>
                            )}
                            {lookUpError && (
                              <div className="p-2 font-semibold text-red-400 bg-red-100 rounded-md font-poppins">
                                {lookUpError}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                  <Form.Item
                    validateStatus={
                      errors?.code.length > 0 ? "error" : undefined
                    }
                    help={errors?.code.length > 0 ? errors.code : undefined}
                    label="Bank"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: "Bank is required",
                      },
                    ]}
                  >
                    <Inputselect
                      placeHolder="Choose Bank"
                      updateField={updateField}
                      name={"code"}
                      selectOptions={supportedBanks}
                      optionHasValue={false}
                      alternativeValue="code"
                      selectOptionLabel="name"
                      loading={loadingSupportedBanks}
                      showSearch={true}
                      selectValue={form.getFieldValue("code")}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label="Email or Phone"
                    name="identifier"
                    rules={[
                      {
                        required: true,
                        message: "Email/Phone is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Email/Phone"
                      onChange={(e) => {
                        updateField("identifier", e?.target?.value);
                      }}
                    />
                  </Form.Item>

                  {form.getFieldValue("identifier") !== undefined && (
                    <div className="mb-2 mt-[-0.5rem]">
                      {lookingUpAccount ? (
                        <Skeletonloader width="100%" height="2.7rem" />
                      ) : (
                        <>
                          {lookupResult && (
                            <div className="p-2 font-semibold text-green-400 bg-green-100 rounded-md font-poppins">
                              {lookupResult?.[0]?.name}
                            </div>
                          )}
                          {lookUpError && (
                            <div className="p-2 font-semibold text-red-400 bg-red-100 rounded-md font-poppins">
                              {lookUpError}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* <Form.Item
                className="w-full"
                validateStatus={
                  errors?.beneficiary.length > 0 ? "error" : undefined
                }
                help={
                  errors?.beneficiary.length > 0
                    ? errors.beneficiary
                    : undefined
                }
                label={"Beneficiary"}
                name="recipient_id"
                rules={[
                  {
                    required: true,
                    message: "Wallet is required",
                  },
                ]}
              >
                <Inputselect
                  placeHolder="Select Beneficiary"
                  updateField={updateField}
                  name={"recipient_id"}
                  selectOptions={beneficiaries}
                  loading={loadingBeneficiaries}
                  selectOptionLabel="beneficiaryLabel"
                  alternativeValue="recipient_id"
                  disabled={form.getFieldValue("currency") === undefined}
                  showSearch={true}
                />
              </Form.Item> */}
              {/* <p
                onClick={() => {
                  navigate("/dashboard/beneficiary");
                }}
                className="mb-4 cursor-pointer text-appcolorblue w-fit"
              >
                Create Beneficiary
              </p> */}
            </div>

            {/* {tabs[0].active ? (
              <>
                <Form.Item
                  validateStatus={
                    errors?.beneficiary.length > 0 ? "error" : undefined
                  }
                  help={
                    errors?.beneficiary.length > 0
                      ? errors.beneficiary
                      : undefined
                  }
                  label="Beneficiary"
                  name="recipient_id"
                  rules={[
                    {
                      required: true,
                      message: "Wallet is required",
                    },
                  ]}
                >
                  <Inputselect
                    placeHolder="Select Beneficiary"
                    updateField={updateField}
                    name={"recipient_id"}
                    selectOptions={beneficiaries}
                    loading={loadingBeneficiaries}
                    selectOptionLabel="beneficiaryLabel"
                    alternativeValue="recipient_id"
                    disabled={form.getFieldValue("currency") === undefined}
                    showSearch={true}
                  />
                </Form.Item>
                <p
                  onClick={() => [navigate("/dashboard/beneficiary")]}
                  className="mb-4 cursor-pointer text-appcolorblue w-fit"
                >
                  Create Beneficiary
                </p>
              </>
            ) : (
              <>
                <Form.Item
                  label="Email or Phone or Wallet ID"
                  name="identifier"
                  rules={[
                    {
                      required: true,
                      message: "Email/Phone/Wallet ID is required",
                    },
                  ]}
                >
                  <Input placeholder="Email/Phone/Wallet ID" />
                </Form.Item>
              </>
            )} */}

            <Form.Item
              validateStatus={errors?.amount.length > 0 ? "error" : undefined}
              help={errors?.amount.length > 0 ? errors.amount : undefined}
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Amount is required",
                },
              ]}
            >
              <Inputamount
                name="amount"
                updateField={updateField}
                placeHolder="Enter amount"
                currency={transformCurrencyToSymbol("ngn")}
                amountValue={form.getFieldValue("amount")}
                // disabled={form.getFieldValue("currency") === undefined}
              />
            </Form.Item>

            <Form.Item
              label="Reason for Transfer or Remark"
              name="remark"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input placeholder="Reason for Transfer / Remark" />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                className="mb-2 "
                disabled={
                  loading ||
                  loadingBeneficiaries ||
                  loadingWallets ||
                  loadingSupportedBanks ||
                  lookingUpAccount ||
                  lookupResult === null
                }
                loading={loading}
              >
                Transfer
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      {showFindBeneficiary && (
        <Selectbeneficiarymodal
          visible={showFindBeneficiary}
          closeModal={closeShowBeneficiary}
          loading={loadingBeneficiaries}
          beneficiaries={beneficiaries}
          loadingBeneficiaries={loadingBeneficiaries}
          updateLookupFromSelectBeneficiary={updateLookupFromSelectBeneficiary}
        />
      )}

      {showTransactionPin && (
        <TransactionPin
          paymentType="transfer"
          visible={showTransactionPin}
          closeModal={closeTransactionPin}
          loading={loading}
          paymentDetails={paymentSummaryDetails}
          onSubmit={followThroughAfterPin}
        />
      )}
    </div>
  );
};

export default TransfersInternal;
